import { Grid, Typography } from "@mui/material";
import { Box, Container } from "@mui/system";
import React from "react";
import img1 from "./img/Block14/img1.jpg";
import img2 from "./img/Block14/img2.jpeg";
import img3 from "./img/Block14/img3.jpeg";
import img4 from "./img/Block14/img4.jpg";
import img5 from "./img/Block14/img5.jpg";
import img6 from "./img/Block14/img6.jpeg";
import img7 from "./img/Block14/img7.jpg";
import img8 from "./img/Block14/img8.jpg";
import img9 from "./img/Block14/img9.jpg";
import img10 from "./img/Block14/img10.jpg";
import img11 from "./img/Block14/img11.jpg";
import img12 from "./img/Block14/img12.jpeg";
import img13 from "./img/Block14/img13.jpeg";

export default function Block14() {
  return (
    <Box sx={{ backgroundColor: "#011D48", padding: 3 }}>
      <Container sx={{ color: "white", textAlign: 'center' }}>
        <Typography variant="h5" sx={{ mb: 2 }}>
          Более 10-ти вариантов ограждений на выбор
        </Typography>
        <Grid 
        container
        direction="row"
        justifyContent="center">
          <Grid xs={12} md={4}>
            <Box>
                <Typography>Wood Steel - Дерево и сталь</Typography>
                <img src={img1} />
            </Box>
          </Grid>
          <Grid xs={12} md={4}>
            <Box>
                <Typography>В рамке с кованными элементами</Typography>
                <img src={img2} />
            </Box>
          </Grid>
          <Grid xs={12} md={4}>
            <Box>
                <Typography>Дизайнерская паутинка</Typography>
                <img src={img3} />
            </Box>
          </Grid>
          <Grid xs={12} md={4}>
            <Box>
                <Typography>Дубовая рамка</Typography>
                <img src={img4} />
            </Box>
          </Grid>
          <Grid xs={12} md={4}>
            <Box>
                <Typography>Классические деревянные</Typography>
                <img src={img5} />
            </Box>
          </Grid>
          <Grid xs={12} md={4}>
            <Box>
                <Typography>Комбинированное по 2 стойки (25 мм) на ступень</Typography>
                <img src={img6} />
            </Box>
          </Grid>
          <Grid xs={12} md={4}>
            <Box>
                <Typography>Композит</Typography>
                <img src={img7} />
            </Box>
          </Grid>
          <Grid xs={12} md={4}>
            <Box>
                <Typography>На Несущем стекле</Typography>
                <img src={img8} />
            </Box>
          </Grid>
          <Grid xs={12} md={4}>
            <Box>
                <Typography>Нержавейка</Typography>
                <img src={img9} />
            </Box>
          </Grid>
          <Grid xs={12} md={4}>
            <Box>
                <Typography>Ограждения с тросами</Typography>
                <img src={img10} />
            </Box>
          </Grid>
          <Grid xs={12} md={4}>
            <Box>
                <Typography>По дизайн проекту</Typography>
                <img src={img11} />
            </Box>
          </Grid>
          <Grid xs={12} md={4}>
            <Box>
                <Typography>Стекло на стойках</Typography>
                <img src={img12} />
            </Box>
          </Grid>
          <Grid xs={12}>
            <Box>
                <Typography>Три типа кованных элементов</Typography>
                <img src={img13} />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
