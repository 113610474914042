import React from "react";
import { Box, Button, Grid, Paper, Typography } from "@mui/material";
import Carousel from "react-material-ui-carousel";
import slide1 from "./img/block13/slider1.jpg";
import { Container } from "@mui/system";
import slide2 from "./img/block13/slider2.jpg";
import slide3 from "./img/block13/slider3.jpg";
import slide4 from "./img/block13/slider4.jpg";
import slide5 from "./img/block13/slider5.jpg";
import slide6 from "./img/block13/slider6.jpg";
import slide7 from "./img/block13/slider7.png";
import slide8 from "./img/block13/slider8.jpg";
import slide9 from "./img/block13/slider9.jpg";
import slide11 from "./img/block13/slider11.jpg";
import slide12 from "./img/block13/slider12.jpg";
import slide13 from "./img/block13/slider13.jpg";
import slide14 from "./img/block13/slider14.jpg";
import slide15 from "./img/block13/slider15.jpg";
import slide16 from "./img/block13/slider16.jpg";
import slide17 from "./img/block13/slider17.jpg";
import slide18 from "./img/block13/slider18.jpg";
import slide19 from "./img/block13/slider19.jpg";
import slide20 from "./img/block13/slider20.jpg";

export default function Example(props) {
  var items = [
    {
      description:
        "На этом объекте мы не ставили каркасы. Они были установлены уже до нас, но были только загрунтованы. Мы их отшпаклевали, зашкурили, покрасили хорошей двухкомпонентной краской. Ступени на лестнице сделали толщиной 60 мм. Это было особенное желание заказчика. Ограждения Wood-steel. Поручни завязаны дубовыми отводами и соединены на скрытый спец-крепеж. Цвет ступеней и  ограждений подбирался индивидуально в цвет ламината. Конкретно тут использовался основной цвет с патинированием. Небольшое удорожание на весь объем, но результат был почти неотличим от ламината.",
      slide: slide1,
    },
    {
      description:
        "Компактная по размеру и деталям лестница на монокосоуре из профильной трубы. Сделана по принципу минимализма. Отсекли все лишнее. Отсекли на столько, на сколько это вообще возможно. Получился вот такой результат. Компактная и легкая лестница. В то же время невероятно прочная и надежная!",
      slide: slide2,
    },
    {
      description:
        "Элегантная лестница с поворотными ступенями на металлическом каркасе. Сделана без опор в пол. Все как мы любим: Если можно не делать лишние столбы, их нужно не делать! По этой работе скоро выйдет большой и подробный видеоотчет, в котором будет показан весь процесс монтажа. От приезда бригады, до прощания с клиентом. Следите за нашим каналом Youtube-канале.  Иногда там появляются новые видео)",
      slide: slide3,
    },
    {
      description:
        "Эта лестница выполнена на железобетонном каркасе. Каркас делали не мы, а другие подрядчики. Кто - не знаем))) Наша же задача состояла в том, чтобы установить на нее ступени и ограждения.\n По нашей рекомендации клиент выровнял, зашпаклевал и покрасил все ступени до установки дерева. Это позволило нам не использовать всякого рода штапики, плинтусы и нащельники при монтаже. Мы сэкономили клиенту деньги, время и выдержали наш главный принцип минимализма - если можно отказаться от использования лишних деталей - от них нужно отказаться...",
      slide: slide4,
    },
    {
      description:
        "Эту лестницу мы сделали в г. Самара дистанционно. Наши клиенты самостоятельно сделали обмеры помещения, а потом так же самостоятельно смонтировали лестницу. Получилось просто классно! Ничего лишнего.",
      slide: slide5,
    },
    {
      description:
        "П-образная лестница с площадкой на металлическом каркасе. Ступени и ограждения сделаны из дуба. Классический вариант лестницы в большой проем. Чтобы уместилась такая лестница проем под нее должен быть минимум 3 метра в длину и 1,8-2,5 метра в ширину. Тогда количество ступеней будет достаточным, чтобы разместить площадку и сделать комфортным высоту и ширину ступеней. Ну а вообще в плане комфорта все строго индивидуально. Для кого-то высота ступеней 220 мм - это нормально, а для некоторых 170 - уже высоко.",
      slide: slide6,
    },
    {
      description:
        "Это долгий, сложный  и очень интересный проект, который мы делали вместе с дизайнерами, работающими над концепцией ремонта в данной двухуровневой квартире.\n Каркас усиленный, с виброгасящими опорами, чтобы не создавалась дополнительная разрушающая нагрузка на несущее стекло. Лестница сделана с интеллектуальной подсветкой. С датчиками движения на входе и на выходе с лестницы. Когда человек проходит мимо датчика, то срабатывает подсветка и поочередно загарается каждая из ступеней. Выглядит очень красиво. В дневное время подсветка автоматически отключается, так как помимо датчиков движения установлен датчик освещенности...",
      slide: slide7,
    },
    {
      description:
        "А эта лестница сделана для двухуровневой квартиры в современном стиле. Модерн - это наш любимый стиль. Тут как раз ценится минимализм и эстетика.\n Каркас лестницы сделан из профильной трубы 100*50. Ступени толщиной 60 мм. Ограждение на несущем закаленном стекле.",
      slide: slide8,
    },
    {
      description:
        "Данный проект реализован в Московской области.\n Тетива из листа 8 мм. Покраска – порошковая. Вся сборка – на болтах. \n Ступени из ясеня с фальш подступенком (свес). Такой вариант визуально увеличивает толщину ступени.\n Ограждение из каленого стекла 10 мм установлено между тетивами. Такая стильная красавица получилась.",
      slide: slide9,
    },
    {
      description:
        "Лестница на металлическом каркасе в дом из оцилиндрованного бревна. Есть люди которые уверяют, что металлической лестнице ну ни как не место в деревянном доме. Когда их спрашиваешь - почему? Они отвечают - потому что! Ну может быть. Мы с ними обычно не спорим, а просто показываем вот такую лестницу в срубовом доме.\n Каркас из профильной трубы. Цвет каркаса - черный муар. Это такой черный-черный, матовый-матовый, глубокий-глубокий цвет! Он просто шикарен! Самое шикарное сочетание - это когда ступени под лаком и не тонированы морилками. Ну это по моему личному мнению...",
      slide: slide11,
    },
    {
      description:
        "Это не лестница! Это воздух! Воздух с подсветкой.\n Нас часто спрашивают – на чем же держатся такие лестницы? Это волшебство.\n На самом деле это консольная лестница. Такие лестницы живут без каркаса и выдерживают нагрузку до 300 кг на ступень.",
      slide: slide12,
    },
    {
      description:
        "Классика. Лестница в Нижегородской области на двойном ломаном косоуре.\n Это лестница установлена в доме в деревне Нижегородской области. Клиенты думали поставить дешевую деревянную лестницу из сосны. Но, все же выбор пал на надежную и красивую лестницу на металлическом каркасе.\n Г-образная лестница с забежными ступенями. Ступени, кстати, из бука – хороший надежный вариант дерева.\n Ограждение – одно из популярных – нержавейка с деревянным поручнем (также выполнен из бука). Монтаж такой лестницы занимает 1-2 дня и никакой сварки на объекте!",
      slide: slide13,
    },
    {
      description:
        "Очень любим это работу. Красота.\n Эту лестницу мы установили во Владимирской области в славном городе М. Долго работали над проектом, чтобы получилось красиво. И получилось.\n Технически. Каркас – монокосоур. Порошковая покраска. Сварка и покраска – на производстве. Никакого колхоза на объекте.\n Ступени выполнены из дуба. Цвет ступеней и каркаса подобран под цвет окон в доме.\n Изюминка проекта – ограждение «Стекло в рамке». Металлические столбы 40х40, деревянный поручень из дуба и стекло закаленное – 10 мм.\n Стильная и надежная лестница.",
      slide: slide14,
    },
    {
      description:
        "Данный проект изготовлен и установлен для наших клиентов из Муромского района.\n Лестница на стереокосоуре (двойной косоур), порошковая покраска и заводская сварка.\n Два комплекта забежных ступеней. Кстати, цвет ступеней получился очень интересным и отлично вписался в интерьер. Ограждение выполнено из композита.\n Дети наших клиентов были в восторге от лестницы и целый вечер после монтажа бегали вверх – вниз по новой лестнице.",
      slide: slide15,
    },
    {
      description:
        "Лестница на центральном монокосоуре и ничего лишнего.\n Идеально подобрана к интерьеру дома. \n Лестницы на монокосоуре получаются открытыми и воздушными.\n Для большей надежности, на ступенях установлены больца.\n Ограждение выполнено из нержавейки с тросами.",
      slide: slide16,
    },
    {
      description:
        "Данную лестницу мы изготовили и установили для клиента из Нижнего Новгорода.\n Лестница П-образная с забежными ступенями. Двойной ломаный косоур.\n Ограждение – нержавейка с тросами и поручень из ясеня в цвет ступеней.\n Ступени также изготовлены из ясеня. Кстати, все ступени на таких лестницах мы устанавливаем на специальные немецкие крепежи дюбель Fisher. Благодаря этому наши лестницы бесшумные.",
      slide: slide17,
    },
    {
      description:
        "Надежная массивная монолитная конструкция.\n Установлена недалеко от Москвы.\n Каркас – тетива из листа 8 мм. Цвет подобран в цвет фасада дома.\n Ступени из ясеня. Цвет ступеней подобран под ламинат. Ступени с подсветкой (но это секрет).\n Ограждение. Стекло 10 мм зажато между двумя тетивами. Надежно, не гремит и не шатается.",
      slide: slide18,
    },
    {
      description:
        "Лестница премиум класса – консольная.\n Такая лестница не оставила равнодушными наших клиентов. Это лестница, которой хочется обладать.\n Консольная лестница выделяет, дополняет ваш интерьер. В то же время заостряет на себе внимание и делает дом неповторимым.",
      slide: slide19,
    },
    {
      description:
        "Классическая лестница на двойном косоуре во Владимирской области. Металлический каркас выполнен на производстве и покрашен порошковой краской. Цвет каркаса – белый.\n Ступени из дуба. Цвет венге – в контраст каркасу.\n Стильное ограждение Wood Steel  с поручнем из дуба. Сочетание дерева и металла на ограждениях придает изюминку помещению.",
      slide: slide20,
    },
  ];

  return (
    <Box id="our_works" sx={{ backgroundColor: "#011D48", padding: 3 }}>
      <Container
        sx={{
          color: "white",
          backgroundColor: "#002A6B",
          padding: 2,
          boxShadow: "0 0 5px 1px white",
        }}
      >
        <Typography variant="h5" sx={{textAlign: 'center', mb: 2}}>Примеры наших работ, на которые стоит взглянуть.</Typography>
        <Carousel
          sx={{ border: "1px solid rgba(255, 255, 255, 0.1)" }}
          navButtonsAlwaysVisible={false}
          animation="slide"
          interval={3000}
          indicators={false}
          autoPlay={false}
        >
          {items.map((item, i) => (
            <Item key={i} item={item} />
          ))}
        </Carousel>
      </Container>
    </Box>
  );
  function Item(props) {
    return (
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="stretch"
        sx={{ backgroundColor: "#011D48", textAlign: "center", height: {xs: '100%', md: 390} }}
      >
        <Grid zIndex={2} xs>
          <Box sx={{ ml: 2, mt: 10, fontSize: 18, color: "white" }}>
            <Typography>{props.item.description}</Typography>
          </Box>
        </Grid>
        <Grid sx={{ p: 2 }}>
          <img src={props.item.slide} />
        </Grid>
      </Grid>
    );
  }
}
