import React from "react";
import { Box, Button, Grid, Paper } from "@mui/material";
import Carousel from "react-material-ui-carousel";
import slide1 from "./img/slider1.png";
import slide11 from "./img/slider11.jpeg";
import slide2 from "./img/slider2.png";
import slide21 from "./img/slider21.jpg";
import slide3 from "./img/slider3.png";
import slide31 from "./img/slider31.jpeg";
import AnchorLink from "react-anchor-link-smooth-scroll";
import {COLORS} from '../values/colors'

export default function Example(props) {
  var items = [
    {
      name: "Лестницы на второй этаж в частный дом или коттедж",
      description: "Ультрасовременные лестницы подойдут в любой интерьер",
      slide: slide1,
      slide2: slide11,
    },
    {
      name: "Изготовление лестниц и оргаждений",
      description:
        "Профессиональное производство лестниц с полным циклом в одном месте",
      slide: slide2,
      slide2: slide21,
    },
    {
      name: "Современные лестницы в двухуровневые квартиры",
      description:
        "Мы прорабатываем и воплощаем в жизнь любые проекты ваши, или ваших дизайнеров",
      slide: slide3,
      slide2: slide31,
    },
  ];

  return (
    <Carousel
      height={780}
      navButtonsAlwaysVisible={false}
      animation="slide"
      interval={7000}
      indicators={false}
      autoPlay={true}
      cycleNavigation={true}
      swipe={false}
    >
      {items.map((item, i) => (
        <Item key={i} item={item} />
      ))}
    </Carousel>
  );
  function Item(props) {
    return (
      <Grid
        container
        spacing={0}
        sx={{ backgroundColor: COLORS.back, textAlign: "right" }}
      >
        <Grid zIndex={2} xs={2} md={20} sx={{ flexGrow: 1, display: { xs: "none", md: "flex" }, height: 800 }}>
          <Box sx={{ ml: 2, mt: 30, width: 400, fontSize: 18, color: "white" }}>
            <h2>{props.item.name}</h2>
            <p>{props.item.description}</p>
            <AnchorLink
              style={{ textDecoration: "none" }}
              offset="150"
              href="#form_callback"
            >
              <Button
                variant="contained"
                size="lg"
                fullWidth={true}
                color="green"
              >
                Консультация
              </Button>
            </AnchorLink>
          </Box>
          <img src={props.item.slide} />
          <img src={props.item.slide2} />
        </Grid>
        <Grid sx={{ flexGrow: 1, display: { xs: "flex", md: "none" }, }}>
          <Box zIndex={2} sx={{ mr: 0.5, mt: 30, fontSize: 18, color: "white", backgroundColor: 'rgba(18, 17, 17, 0.4)', borderRadius: 4 }}>
            <h2>{props.item.name}</h2>
            <p>{props.item.description}</p>
            <AnchorLink
              style={{ textDecoration: "none" }}
              offset="150"
              href="#form_callback"
            >
              <Button
                variant="contained"
                size="lg"
                fullWidth={true}
                color="green"
              >
                Консультация
              </Button>
            </AnchorLink>
          </Box>
          <Box sx={{position: 'absolute', flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <img src={props.item.slide} />
          </Box>
        </Grid>
      </Grid>
    );
  }
}
