import { Container } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import Form from "./Form";

export default function Block5() {
  return (
    <Box sx={{ backgroundColor: "#011D48" }}>
      <Container><br/></Container>
      <Form />
    </Box>
  );
}
