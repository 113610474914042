import React from "react";
import bg from "./img/block8.png";
import { Box, Container } from "@mui/system";
import {
  Button,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Typography,
} from "@mui/material";
import card1 from "./img/card1.jpg";
import card2 from "./img/card2.jpg";
import card3 from "./img/card3.jpg";
import AnchorLink from "react-anchor-link-smooth-scroll";

export default function Block8() {
  return (
    <Container>
      <Box
        sx={{
          backgroundImage: `url(${bg})`,
          backgroundRepeat: "no-repeat",
          position: "relative",
        }}
      >
        <Box sx={{ textAlign: "center", mt: 0 }}>
          <Typography sx={{}} variant="h4">
            Современное производство лестниц{" "}
            <span style={{ color: "#e91e63" }}>полного цикла</span>
          </Typography>
        </Box>
        <Grid container sx={{ textAlign: "center" }}>
          <Grid xs>
            <Card sx={{ maxWidth: 345 }}>
              <CardMedia sx={{ height: 230, width: 340 }} image={card1} />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  Заводская сварка каркаса
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid xs>
            <Card sx={{ maxWidth: 345 }}>
              <CardMedia sx={{ height: 230, width: 340 }} image={card2} />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  Порошковая покраска
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid xs>
            <Card sx={{ maxWidth: 345 }}>
              <CardMedia sx={{ height: 230, width: 340 }} image={card3} />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  Профессиональная подготовка и покраска ступеней
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
      <Box>
        <Grid container sx={{ my: 4}}>
          <Grid xs>
            <Typography variant="h5" component="div">
              Закажите все это в одном месте{" "}
              <span style={{ color: "#e91e63" }}>под ключ!</span>
            </Typography>
          </Grid>
          <Grid xs sx={{textAlign: 'right'}}>
          <AnchorLink
                style={{ textDecoration: "none" }}
                offset="150"
                href="#form_callback"
              >
            <Button
              variant="contained"
              size="lg"
              fullWidth={false}
              color="green"
            >
              Заказать звонок
            </Button>
            </AnchorLink>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
}
