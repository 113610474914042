import { Box } from "@mui/system";
import React from "react";
import { YMaps, Map, Placemark } from "@pbe/react-yandex-maps";
import { Container, Paper, Typography } from "@mui/material";

export default function Block15() {
  const defaultState = {
    center: [55.751574, 37.573856],
    zoom: 12,
  };
  return (
    <Box sx={{ backgroundColor: "#011D48", padding: 0 }}>
      <Container>
        <Typography
          sx={{
            color: "white",
            textAlign: "center",
            display: { xs: "flex", md: "none" },
          }}
          variant="h5"
        >
          Работаем во Владимирской и Нижегородской областях
        </Typography>
      </Container>
      <Box
        sx={{
          color: "black",
          position: "relative",
          top: 130,
          zIndex: 2,
          ml: 40,
          backgroundColor: "white",
          padding: 1,
          boxShadow: "0 0 5px 1px black",
          width: 350,
          display: { xs: "none", md: "flex" },
        }}
      >
        <Typography variant="h5">Работаем во Владимирской и Нижегородской областях</Typography>
      </Box>
      <YMaps>
        <Map width="100%" defaultState={defaultState} />
      </YMaps>
    </Box>
  );
}
