import { Grid, Typography } from "@mui/material";
import { Box, Container, Stack } from "@mui/system";
import React from "react";
import bg from "./img/block11.png";
import HandymanOutlinedIcon from "@mui/icons-material/HandymanOutlined";
import ExtensionOutlinedIcon from "@mui/icons-material/ExtensionOutlined";
import BuildOutlinedIcon from "@mui/icons-material/BuildOutlined";
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import ViewInArOutlinedIcon from '@mui/icons-material/ViewInArOutlined';

export default function Block11() {
  return (
    <Box sx={{}}>
      <Container sx={{ my: 15 }}>
        <Typography sx={{ textAlign: "center" }} variant="h4">
          {" "}
          Болтовое соединение даёт возможность провести сборку{" "}
          <span style={{ color: "#e91e63" }}>самостоятельно</span>{" "}
        </Typography>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="stretch"
        >
          <Grid xs>
            <Box sx={{}}>
              <Stack spacing={5}>
                <Box
                  sx={{
                    boxShadow: "0 0 3px gray",
                    textAlign: "center",
                    borderRadius: 4,
                  }}
                >
                  <Stack direction="row">
                    <HandymanOutlinedIcon sx={{ fontSize: 50, padding: 2 }} />
                    <Typography>
                      Лестницы собираются на болтовом соединении без применения
                      сварки и спец инструмента
                    </Typography>
                  </Stack>
                </Box>
                <Box
                  sx={{
                    boxShadow: "0 0 3px gray",
                    textAlign: "center",
                    borderRadius: 4,
                  }}
                >
                  <Stack direction="row">
                    <ExtensionOutlinedIcon sx={{ fontSize: 50, padding: 2 }} />
                    <Typography>
                      Каркас лестницы состоит из 4-5 частей в зависимости от
                      конфигурации
                    </Typography>
                  </Stack>
                </Box>
                <Box
                  sx={{
                    boxShadow: "0 0 3px gray",
                    textAlign: "center",
                    borderRadius: 4,
                  }}
                >
                  <Stack direction="row">
                    <ViewInArOutlinedIcon sx={{ fontSize: 50, padding: 2 }} />
                    <Typography>
                      Мы предоставляем чертежи и дополнительные схемы лестницы и
                      всегда готовы помочь в режиме онлайн
                    </Typography>
                  </Stack>
                </Box>
              </Stack>
            </Box>
          </Grid>
          <Grid xs sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }}}>
            <Box >
                <img src={bg} />
            </Box>
          </Grid>
          <Grid xs>
            <Box sx={{}}>
              <Stack spacing={5}>
                <Box
                  sx={{
                    boxShadow: "0 0 3px gray",
                    textAlign: "center",
                    borderRadius: 4,
                    mt: 2
                  }}
                >
                  <Stack direction="row">
                    <Typography>
                      Монтаж каркаса занимает от 45 минут до 2-х часов. Для
                      монтажа понадобятся шуруповерт или дрель, перфоратор,
                      уровень, молоток и набор ключей.
                    </Typography>
                    <BuildOutlinedIcon sx={{ fontSize: 50, padding: 2 }} />
                  </Stack>
                </Box>
                <Box
                  sx={{
                    boxShadow: "0 0 3px gray",
                    textAlign: "center",
                    borderRadius: 4,
                  }}
                >
                  <Stack direction="row">
                    <Typography>
                    Вся лестница "под ключ" устанавливается силами двух человек за 1-1,5 дня
                    </Typography>
                    <PeopleAltOutlinedIcon sx={{ fontSize: 50, padding: 2 }} />
                  </Stack>
                </Box>
              </Stack>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
