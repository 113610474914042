import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import AdbIcon from "@mui/icons-material/Adb";
import logo from "./img/logo.png";
import PhonelinkRingIcon from "@mui/icons-material/PhonelinkRing";
import PhoneInTalkIcon from "@mui/icons-material/PhoneInTalk";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import AnchorLink from "react-anchor-link-smooth-scroll";

const pages = ["Цены", "Как работаем", "Наши работы", "Контакты"];
const viber_url = "viber://chat?number=+79268662750/";
const whatsapp =
  "https://api.whatsapp.com/send?phone=79268662750&text=%20%D0%97%D0%B4%D1%80%D0%B0%D0%B2%D1%81%D1%82%D0%B2%D1%83%D0%B9%D1%82%D0%B5!%20%D0%A5%D0%BE%D1%87%D1%83%20%D0%BF%D1%80%D0%BE%D0%BA%D0%BE%D0%BD%D1%81%D1%83%D0%BB%D1%8C%D1%82%D0%B8%D1%80%D0%BE%D0%B2%D0%B0%D1%82%D1%8C%D1%81%D1%8F%20%D0%BF%D0%BE%20%D1%81%D1%82%D0%BE%D0%B8%D0%BC%D0%BE%D1%81%D1%82%D0%B8%20%D0%BB%D0%B5%D1%81%D1%82%D0%BD%D0%B8%D1%86%D1%8B.%20%D0%9A%D0%B0%D0%BA%D0%B8%D0%B5%20%D0%B4%D0%B0%D0%BD%D0%BD%D1%8B%D0%B5%20%D0%B2%D0%B0%D0%BC%20%D0%BD%D1%83%D0%B6%D0%BD%D1%8B?";

function Navbar() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <AppBar color="sec" position="fixed">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: "none", md: "flex" },
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
            }}
          >
            <img src={logo} />
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              <AnchorLink
                style={{ textDecoration: "none" }}
                offset="150"
                href="#form_callback"
              >
                <MenuItem onClick={handleCloseNavMenu}>
                  <Button fontFamily="Roboto" textAlign="center">
                    ЦEНЫ
                  </Button>
                </MenuItem>
              </AnchorLink>
              <AnchorLink
                style={{ textDecoration: "none" }}
                offset="50"
                href="#how_work"
              >
                <MenuItem onClick={handleCloseNavMenu}>
                  <Button fontFamily="Roboto" textAlign="center">
                    КАК РАБОТАЕМ
                  </Button>
                </MenuItem>
              </AnchorLink>
              <AnchorLink
                style={{ textDecoration: "none" }}
                offset="50"
                href="#our_works"
              >
                <MenuItem onClick={handleCloseNavMenu}>
                  <Button fontFamily="Roboto" textAlign="center">
                    НАШИ РАБОТЫ
                  </Button>
                </MenuItem>
              </AnchorLink>
              <AnchorLink
                style={{ textDecoration: "none" }}
                offset="300"
                href="#contacts"
              >
                <MenuItem onClick={handleCloseNavMenu}>
                  <Button fontFamily="Roboto" textAlign="center">
                    Контакты
                  </Button>
                </MenuItem>
              </AnchorLink>
            </Menu>
          </Box>
          <Typography
            variant="h5"
            noWrap
            component="a"
            href=""
            sx={{
              mr: 2,
              display: { xs: "flex", md: "none" },
              flexGrow: 1,
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
            }}
          >
            <img src={logo} />
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            <AnchorLink style={{ textDecoration: "none" }} offset="300" href="#form_callback">
              <Button sx={{ my: 2, color: "white", display: "block" }}>
                Цены
              </Button>
            </AnchorLink>
            <AnchorLink style={{ textDecoration: "none" }} offset="200" href="#how_work">
              <Button sx={{ my: 2, color: "white", display: "block" }}>
                Как работаем
              </Button>
            </AnchorLink>
            <AnchorLink style={{ textDecoration: "none" }} offset="200" href="#our_works">
              <Button sx={{ my: 2, color: "white", display: "block" }}>
                Наши работы
              </Button>
            </AnchorLink>
            <AnchorLink style={{ textDecoration: "none" }} offset="300" href="#contacts">
              <Button sx={{ my: 2, color: "white", display: "block" }}>
                Контакты
              </Button>
            </AnchorLink>
          </Box>
          <Box sx={{ mx: 0 }}>
            <a href={whatsapp}>
              <WhatsAppIcon color="green" />
            </a>
          </Box>
          <Box sx={{ mx: 2 }}>
            <a href={viber_url}>
              <PhoneInTalkIcon color="viber" />
            </a>
          </Box>
          <Box sx={{ flexGrow: 0 }}>
            <Tooltip>
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <PhonelinkRingIcon color="whiteq" />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              <MenuItem onClick={handleCloseUserMenu}>
              <AnchorLink
                style={{ textDecoration: "none" }}
                offset="150"
                href="#form_callback"
              ><Typography textAlign="center">Заказать звонок</Typography>
              </AnchorLink>
              </MenuItem>
            </Menu>
          </Box>
          <Box sx={{ mx: 1, display: { xs: "none", md: "flex" } }}>
            <Typography> +79268662750</Typography>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default Navbar;
