import { Typography } from "@mui/material";
import { Container, Stack } from "@mui/system";
import React from "react";
import StraightenOutlinedIcon from "@mui/icons-material/StraightenOutlined";
import PaletteOutlinedIcon from "@mui/icons-material/PaletteOutlined";
import ViewInArOutlinedIcon from "@mui/icons-material/ViewInArOutlined";
import WorkHistoryOutlinedIcon from '@mui/icons-material/WorkHistoryOutlined';

export default function Block12() {
  return (
    <Container>
      <Stack direction="row" spacing={3} sx={{ my: 3 }}>
        <StraightenOutlinedIcon sx={{ fontSize: 40 }} />
        <Typography sx={{ fontSize: 18 }}>
          Оставьте заявку на замер, или позвоните нам. Или сделайте замер
          лестницы самостоятельно, чтобы сэкономить время и деньги.
        </Typography>
      </Stack>
      <Stack direction="row" spacing={3} sx={{ my: 3 }}>
        <PaletteOutlinedIcon sx={{ fontSize: 40 }} />
        <Typography sx={{ fontSize: 18 }}>
          Выберите подходящий каркас будущей лестницы, подберите понравившийся
          вам материал ступеней, перила и цвет
        </Typography>
      </Stack>
      <Stack direction="row" spacing={3} sx={{ my: 3 }}>
        <ViewInArOutlinedIcon sx={{ fontSize: 40 }} />
        <Typography sx={{ fontSize: 18 }}>
          Заключите договор и согласуйте окончательный 3D проект c нашим
          дизайнером
        </Typography>
      </Stack>
      <Stack direction="row" spacing={3} sx={{ my: 3 }}>
        <WorkHistoryOutlinedIcon sx={{ fontSize: 40 }} />
        <Typography sx={{ fontSize: 18 }}>
          Выберите подходящее время для монтажа и ждите исполнения вашего заказа
        </Typography>
      </Stack>
    </Container>
  );
}
