import { Button, Container, Grid, Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";
import React from "react";
import block1 from "./img/block1.png";
import TrendingFlatIcon from "@mui/icons-material/TrendingFlat";
import SecurityIcon from "@mui/icons-material/Security";
import StairsOutlinedIcon from "@mui/icons-material/StairsOutlined";
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined';
import {COLORS} from '../values/colors'
import HourglassDisabledOutlinedIcon from '@mui/icons-material/HourglassDisabledOutlined';

export default function Block1() {
  return (
    <Box sx={{ backgroundColor: COLORS.back, backgroundImage: `url(${block1})` }}>
      <Container sx={{ padding: 3, textAlign: "center" }}>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid xs>
            <Typography
              variant="h2"
              sx={{ fontFamily: "Roboto Bold", fontSize: 32, mb: 5 }}
              color="white"
              align="center"
            >
              Почему мы отдаём предпочтение изготовлению лестниц на
              металлическом каркасе ?
            </Typography>
          </Grid>
          <Grid xs={6} sx={{display: { xs: "none", md: "flex" }, flexGrow: 1}}>
            <Stack spacing={5}>
              <Stack direction="row">
                <SecurityIcon sx={{ fontSize: 50 }} color="whiteq" />
                <Typography sx={{ color: "white", fontFamily: "Roboto Italic" }}>
                  Металлическая лестница надежна. При грамотной установке она не
                  скрипит и не шатается. имеет больший ресурс, чем деревянная
                </Typography>
              </Stack>
              <Stack direction="row">
                <StairsOutlinedIcon sx={{ fontSize: 50 }} color="whiteq" />
                <Typography sx={{ color: "white", fontFamily: "Roboto Italic" }}>
                  Лестница на металлокаркасе вписывается в любой интерьер. она
                  может быть прямой, Г - образной, П - образной, винтовой,
                  криволинейной. для эксклюзивных лестниц под заказ нет
                  невозможных форм
                </Typography>
              </Stack>
              <Stack direction="row">
                <AccountTreeOutlinedIcon sx={{ fontSize: 50 }} color="whiteq" />
                <Typography sx={{ color: "white", fontFamily: "Roboto Italic" }}>
                  Металлокаркас можно связать с любыми перилами. к ним одинаково
                  подходят кованые перила, нержавеющие перила, интерьерные
                  ограждения для лестниц, ограждения с пластиковым поручнем и
                  деревянным поручнем
                </Typography>
              </Stack>
              <Stack direction="row">
                <HourglassDisabledOutlinedIcon sx={{ fontSize: 50 }} color="whiteq" />
                <Typography sx={{ color: "white", fontFamily: "Roboto Italic" }}>
                  Такая лестница имеет неограниченный срок службы. даже через
                  много лет она сохраняет свой первозданный вид
                </Typography>
              </Stack>
            </Stack>
          </Grid>
          <Grid xs={12} sx={{display: { xs: "flex", md: "none" }, flexGrow: 1}}>
            <Stack spacing={5}>
              <Stack direction="row">
                <SecurityIcon sx={{ fontSize: 50 }} color="whiteq" />
                <Typography sx={{ color: "white", fontFamily: "Roboto Italic" }}>
                  Металлическая лестница надежна. При грамотной установке она не
                  скрипит и не шатается. имеет больший ресурс, чем деревянная
                </Typography>
              </Stack>
              <Stack direction="row">
                <StairsOutlinedIcon sx={{ fontSize: 50 }} color="whiteq" />
                <Typography sx={{ color: "white", fontFamily: "Roboto Italic" }}>
                  Лестница на металлокаркасе вписывается в любой интерьер. она
                  может быть прямой, Г - образной, П - образной, винтовой,
                  криволинейной. для эксклюзивных лестниц под заказ нет
                  невозможных форм
                </Typography>
              </Stack>
              <Stack direction="row">
                <AccountTreeOutlinedIcon sx={{ fontSize: 50 }} color="whiteq" />
                <Typography sx={{ color: "white", fontFamily: "Roboto Italic" }}>
                  Металлокаркас можно связать с любыми перилами. к ним одинаково
                  подходят кованые перила, нержавеющие перила, интерьерные
                  ограждения для лестниц, ограждения с пластиковым поручнем и
                  деревянным поручнем
                </Typography>
              </Stack>
              <Stack direction="row">
                <HourglassDisabledOutlinedIcon sx={{ fontSize: 50 }} color="whiteq" />
                <Typography sx={{ color: "white", fontFamily: "Roboto Italic" }}>
                  Такая лестница имеет неограниченный срок службы. даже через
                  много лет она сохраняет свой первозданный вид
                </Typography>
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
