import { Grid, Typography } from "@mui/material";
import { Box, Container, Stack } from "@mui/system";
import React from "react";
import bg from "./img/block9bg.png";
import BoltIcon from "@mui/icons-material/Bolt";
import BeachAccessOutlinedIcon from '@mui/icons-material/BeachAccessOutlined';
import TerrainIcon from '@mui/icons-material/Terrain';
import FormatPaintIcon from '@mui/icons-material/FormatPaint';
import block9 from './img/block9.jpg';

export default function Block9() {
  return (
    <Box
      sx={{
        backgroundImage: `url(${bg})`,
        backgroundColor: "#011D48",
        color: "white",
      }}
    >
      <Container>
        <Grid container
        justifyContent="center"
        alignItems="center">
          <Grid xs sx={{ my: 10 }}>
            <Typography variant="h4">
              Окрашивание порошковыми красками на{" "}
              <span style={{ color: "#e91e63" }}>современном </span>оборудовании
            </Typography>
            <Typography fontFamily="Roboto Italic">
              Это решение №1 для лестниц 21 века:
            </Typography>
            <Box sx={{ mt: 5 }}>
              <Stack spacing={5}>
                <Stack direction="row">
                  <BoltIcon sx={{ fontSize: 35 }} color="whiteq" />
                  <Typography
                    variant="h7"
                    sx={{ color: "white", fontFamily: "Roboto", mt: 1 }}
                  >
                    Она устойчива к ударам и царапинам
                  </Typography>
                </Stack>
                <Stack direction="row">
                  <BeachAccessOutlinedIcon sx={{ fontSize: 35 }} color="whiteq" />
                  <Typography
                    variant="h7"
                    sx={{ color: "white", fontFamily: "Roboto", mt: 1 }}
                  >
                    Не выгорает на солнце. через много лет ваш каркас выглядит,
                    как только что выкрашенный
                  </Typography>
                </Stack>
                <Stack direction="row">
                  <TerrainIcon sx={{ fontSize: 35 }} color="whiteq" />
                  <Typography
                    variant="h7"
                    sx={{ color: "white", fontFamily: "Roboto", mt: 1 }}
                  >
                    У порошковых красок много видов декоративных покрытий. это
                    шагрени, муары, антики, крокодиловая кожа. эти эффекты
                    сделают вашу лестницу уникальной!
                  </Typography>
                </Stack>
                <Stack direction="row">
                  <FormatPaintIcon sx={{ fontSize: 35 }} color="whiteq" />
                  <Typography
                    variant="h7"
                    sx={{ color: "white", fontFamily: "Roboto", mt: 1 }}
                  >
                    Это стоит дешевле услуг профессионального маляра!
                  </Typography>
                </Stack>
              </Stack>
            </Box>
          </Grid>
          <Grid xs>
            <Box sx={{backgroundImage: `url(${block9})`, width: 360, height: 300, backgroundRepeat: 'no-repeat'}}>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
