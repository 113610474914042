import { Typography } from '@mui/material'
import { Box, Container } from '@mui/system'
import React from 'react'


const styleLi = {
    width: 250,
    margin: "0 15px 20px",
    padding: "20px 30px 20px",
    background: "#0a3a85",
    borderRadius: "50px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    listStyle: "none",
    textAlign: "center",
    marginBottom: "13px",
    position: "relative",
    minHeight: "120px",
}

const styleOl = {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    marginRight: 50,
}

const styleLiLast = {
    width: 250,
    margin: "0 15px 20px",
    padding: "20px 30px 20px",
    borderRadius: "50px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    listStyle: "none",
    textAlign: "center",
    marginBottom: "13px",
    position: "relative",
    minHeight: "120px",
    backgroundColor: '#E06D06'
}

export default function Block6() {
  return (
    <Box id="how_work" sx={{ backgroundColor: '#011D48', color: 'white', padding: 1}}>
        <Container sx={{ boxShadow: "0 0 5px 2px white", padding: 1,  textAlign: 'center'}}>
        <h3 class="title-second fw-bold ta-center">Как работаем:</h3>
				<ol class="who-we__ol" style={styleOl}>
					<li value="1" class="who-we__li" style={styleLi}><Typography  sx={{fontSize: 30, fontFamily: 'impact'}}>1</Typography>&nbsp; Оставляете заявку</li>
					<li class="who-we__li" style={styleLi}><Typography  sx={{fontSize: 30, fontFamily: 'impact'}}>2</Typography>&nbsp;Созваниваемся, обсуждаем детали проекта вашей лестницы</li>
					<li class="who-we__li arrow-down" style={styleLi}><Typography  sx={{fontSize: 30, fontFamily: 'impact'}}>3</Typography>&nbsp;Готовим предварительный расчет и строим удобную лестницу</li>
					<li class="who-we__li arrow-down2" style={styleLi}><Typography  sx={{fontSize: 30, fontFamily: 'impact'}}>4</Typography>&nbsp;Выезжаем на замер</li>
					<li class="who-we__li arrow-before" style={styleLi}><Typography  sx={{fontSize: 30, fontFamily: 'impact'}}>5</Typography>&nbsp;Заключаем договор и готовим проект</li>
					<li class="who-we__li no-arrow" style={styleLi}><Typography  sx={{fontSize: 30, fontFamily: 'impact'}}>6</Typography>&nbsp;Производство и монтаж</li>
					<li class="who-we__li  who-we__li--last" style={styleLiLast}><Typography  sx={{fontSize: 30, fontFamily: 'impact'}}>7</Typography>&nbsp;Ваша лестница готова</li>
				</ol>
        </Container>
    </Box>
  )
}
