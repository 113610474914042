import React from "react";
import "./App.css";
import Navbar from "./components/Navbar";
import { createTheme } from "@mui/material/styles";
import { ThemeProvider } from "@emotion/react";
import Example from "./components/Carousel";
import { Container } from "@mui/material";
import Block1 from "./components/Block1";
import Block2 from "./components/Block2";
import Block3 from "./components/Block3";
import Block4 from "./components/Block4";
import Block5 from "./components/Block5";
import Block6 from "./components/Block6";
import Block7 from "./components/Block7";
import Block8 from "./components/Block8";
import Block9 from "./components/Block9";
import Block10 from "./components/Block10";
import Block11 from "./components/Block11";
import Block12 from "./components/Block12";
import Block13 from "./components/Block13";
import Block14 from "./components/Block14";
import Block15 from "./components/Block15";
import Footer from "./components/Footer";

const theme = createTheme({
  status: {
    danger: "#e53e3e",
  },
  palette: {
    primary: {
      main: "#0971f1",
      darker: "#053e85",
    },
    sec: {
      main: "#011D48",
      contrastText: "#fff",
    },
    viber: {
      main: "#e040fb",
      contrastText: "#fff",
    },
    green: {
      main: "#00e676",
      contrastText: "#fff",
    },
    whiteq: {
      main: "#fafafa",
      contrastText: "#fff",
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Navbar />
      <Example />
      <Block1 />
      <Block2 />
      <Block3 />
      <Block6 />
      <Block7 />
      <Block8 />
      <Block9 />
      <Block10 />
      <Block11 />
      <Block12 />
      <Block13 />
      <Block3 />
      <Block4 />
      <Block5 />
      <Block14 />
      <Block15/>
      <Footer/>
    </ThemeProvider>
  );
}

export default App;
