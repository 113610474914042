import {
  Button,
  Container,
  Grid,
  Input,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const TOKEN = "5697673798:AAFCdbyRJpsRMfC3YhZewfAD8IPhBt_uqxo";
const CHAT_ID = "-1001705256644";
const URL_API = `https://api.telegram.org/bot${TOKEN}/sendMessage`;

export default function Form() {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const onSubmit = (data) => {
    let message = "<b>Новый лид!</b>\n";
    message += `Имя: ${data.firstName}\n`;
    message += `Телефон: ${data.phone}\n`;
    message += `Почта: ${data.mail}\n`;
    message += `Комментарий: ${data.comment}\n`;
    axios
      .post(URL_API, {
        chat_id: CHAT_ID,
        parse_mode: "html",
        text: message,
      })
      .then((res) => {
        toast.success("Заявка успешно отправлена", {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      })
      .catch((err) => {
        console.warn(err);
      });
  };

  return (
    <Container id="form_callback" alignItems="center">
      <ToastContainer
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        autoComplete="off"
        sx={{
          backgroundColor: "#fff",
          borderRadius: 5,
          padding: 3,
          boxShadow: "0 0 5px 2px white",
          textAlign: "center",
        }}
      >
        <Typography variant="h5">
          Узнайте стоимость лестницы за{" "}
          <span style={{ color: "#e91e63" }}>5 минут!</span>
        </Typography>
        <Typography variant="h7">
          Просто укажите известные размеры проема в заявке и мы перезвоним вам и
          назовем точную стоимость лестницы
        </Typography>
        <Box display="flex" justifyContent="center">
          <Stack
            direction={{ xs: "column", sm: "row" }}
            spacing={{ xs: 1, sm: 2, md: 4 }}
          >
            <TextField
              {...register("firstName")}
              id="standard-basic"
              label="Имя"
              variant="standard"
              required
            />
            <TextField
              {...register("phone")}
              id="standard-basic"
              label="Телефон"
              variant="standard"
            />
          </Stack>
        </Box>
        <Box display="flex" justifyContent="center">
          <Stack
            direction={{ xs: "column", sm: "row" }}
            spacing={{ xs: 1, sm: 2, md: 4 }}
          >
            <TextField
              {...register("mail")}
              id="standard-basic"
              label="Почта"
              variant="standard"
              required
              type="email"
            />
          </Stack>
        </Box>
        <TextField
          {...register("comment")}
          sx={{ mt: 3 }}
          fullWidth="true"
          id="outlined-multiline-static"
          label="Комментарий"
          multiline
          rows={5}
        />
        <Grid xs="12" sx={{ mt: 1 }}>
          <Button
            type="submit"
            variant="contained"
            size="lg"
            color="green"
            sx={{ mt: 1 }}
          >
            Отправить
          </Button>
        </Grid>
      </Box>
    </Container>
  );
}
